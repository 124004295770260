.general-layout {
    min-height: $main-content-height;
    position: relative;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .Toastify__toast {
    border-radius: 5px !important;
  }
