*,
*::after,
*::before  {
  font-family: "Open Sans", sans-serif;
}

html,
body,
#root {
  width:100%;
  height:100%;
  padding:0;
  margin:0;
  color: $primary-color__default;
  font-weight: 400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
  -webkit-text-fill-color: #dbdbdb; /* sets text fill to current `color` mainly targeting safari */
}


input[type=number] {
  -moz-appearance: textfield;
}

button, select {
  outline: none;
  &:active, &:focus {
    outline: none;
  }
}

::placeholder {
  color: #999191;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999191;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #999191;
}
