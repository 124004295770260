.modal {
    width: 28rem;
  
    //&-close-button {
    //  .MuiPopover-paper {
    //    background-color: transparent !important;
    //    box-shadow: none;
    //  }
    //}
  }
